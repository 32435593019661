import * as constants from '../consts/index'

import { Link, graphql } from 'gatsby'

// import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-6.svg";
// import { ReactComponent as SvgDecoratorBlob2 } from "../images/svg-decorator-blob-7.svg";
import { CallToAction } from '../components/components/cta/GetStarted'
// import Img from 'gatsby-image'
import Layout from '../components/layout'
import { ReactComponent as NextButtonSvg } from '../images/next-button.svg'
import { ProductListCard } from '../components/ProductListingCard'
import React from 'react'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import tw from 'twin.macro'
import SEO from '../components/seo'

const ContentContainer = tw.div`max-w-screen-lg relative mx-auto py-8 lg:py-12 flex flex-col z-10`

//TODO: Not sure why do we need the margin right here?
// FIXED: We don't need it
const TabContent = tw(motion.div)`mt-6 flex flex-wrap -mx-4 px-4`
// const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
//   ${tw`pointer-events-none opacity-25 absolute hidden lg:block left-0 bottom-0 h-1/2 w-1/2 transform -translate-x-1/3 -z-10`}
// `;
// const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
//   ${tw`pointer-events-none absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-green-300`}
// `;

const NextButton = styled(NextButtonSvg)`
  ${tw`h-4 w-4`}
`

const Container = tw.div`relative bg-gray-900 text-gray-100 -mx-8 px-8`
const ProductContainer = tw.div`w-full flex flex-wrap px-8 lg:-mx-4 lg:bg-white lg:shadow-sm lg:rounded-lg lg:border`
// Might need this
{
  /*<div className="w-full flex flex-wrap px-8 lg:-mx-4 lg:bg-white lg:shadow-sm lg:rounded-lg lg:shadow-raised">*/
}

const ImageContainer = tw.div`w-auto mx-auto lg:w-1/2 lg:px-4 my-8`

const WhiteBackgroundOverlay = tw.div`absolute inset-x-0 bottom-0 h-0 lg:h-1/2 bg-white z-0`

const LinkName = tw.a`text-green-500`
// const ProductImage = styled.Img`
//   ${tw`h-64 md:h-128 rounded-lg`}
// `;
const ProductInfoContainer = tw.div`w-full lg:w-1/2 p-4 lg:p-8 mb-8`
const ProductBreadcumbContainer = tw.div`flex items-center text-sm text-green-500`
const ProductTextContainer = tw.div`mt-8`
const ProductBrandName = tw.h2`font-medium text-gray-400 lg:text-gray-400 tracking-wider uppercase`
const ProductName = tw.h1`font-semibold text-2xl text-gray-100 lg:text-gray-800`
const ProductDescription = tw.p`mt-6 text-lg text-gray-300 lg:text-gray-600 leading-relaxed`

// This is for the decorator to work
const ContentWidestContainer = tw.div`mt-12 pb-24 relative bg-gray-50`
const ContentLargerContainer = tw.div`max-w-screen-xl mx-auto pb-8 lg:pb-12 flex flex-col`
const RelatedProductTitleContainer = tw.div`w-full mt-8 lg:mt-12 flex flex-col mb-4 flex items-center justify-center`
const RelatedProductTitle = tw.h1`text-3xl sm:text-4xl font-bold text-gray-700 leading-relaxed`

// eslint-disable-next-line react/prop-types
export default function Post({ data }) {
  const product = data.templateSheetsData
  // eslint-disable-next-line
  let ids = data.data.nodes.map((o) => o.psproductname)
  let filtered = data.data.nodes.filter(
    ({ psproductname }, index) => !ids.includes(psproductname, index + 1)
  )

  filtered = filtered.filter((o) => o.psproductname != product.psproductname)
  var randomItemsToRender = []

  for (var i = 0; i <= 2; i++) {
    var randomIndex = Math.floor(Math.random() * filtered.length)
    var item = filtered[randomIndex]
    filtered.splice(randomIndex, 1)
    randomItemsToRender.push(item)
  }

  return (
    <Layout bgColor={constants.bgColor} isDark={constants.isDark}>
      <SEO
        keywords={[
          `products`,
          `product`,
          `sinaran rempah`,
          `spices`,
          `spices supplier`,
          `spices malaysia`,
        ]}
        title={product.psproductname}
      />
      <Container>
        <ContentContainer>
          <ProductContainer>
            <ImageContainer>
              <div className="h-auto">
                <img
                  className="h-auto md:h-auto md:min-h-96 md:max-h-128 w-64 md:w-128 lg:w-auto rounded-lg mx-auto"
                  sizes="(max-width: 447px) 100vw, 447px"
                  loading="lazy"
                  srcSet={`https://ik.imagekit.io/r703ihwwlwd/sinaran/tr:w-447/${product.psitemcoverimage} 112w,
                          https://ik.imagekit.io/r703ihwwlwd/sinaran/tr:w-447/${product.psitemcoverimage} 224w,
                          https://ik.imagekit.io/r703ihwwlwd/sinaran/tr:w-447/${product.psitemcoverimage} 447w,
                          https://ik.imagekit.io/r703ihwwlwd/sinaran/tr:w-447/${product.psitemcoverimage} 671w,
                          https://ik.imagekit.io/r703ihwwlwd/sinaran/tr:w-447/${product.psitemcoverimage} 894w,
                          https://ik.imagekit.io/r703ihwwlwd/sinaran/tr:w-447/${product.psitemcoverimage} 984w`}
                  src={`https://ik.imagekit.io/r703ihwwlwd/sinaran/tr:w-447/${product.psitemcoverimage}`}
                />
                {/* <Img
                  fluid={data.file.childImageSharp.fluid}
                  className={
                    'h-64 md:h-128 w-64 md:w-128 lg:w-auto rounded-lg hidden'
                  }
                /> */}
              </div>
            </ImageContainer>
            <ProductInfoContainer>
              <ProductBreadcumbContainer>
                <LinkName as={Link} to={`/category/all`}>
                  Products
                </LinkName>
                <NextButton />
                <LinkName
                  as={Link}
                  to={`/category/${product.pscategory.toLowerCase()}`}
                >
                  {product.pscategory}
                </LinkName>
              </ProductBreadcumbContainer>
              <ProductTextContainer>
                <ProductBrandName>{product.psbrandname}</ProductBrandName>
                <ProductName>{product.psproductname}</ProductName>
                <ProductDescription>
                  {product.psproductdescription}
                </ProductDescription>
              </ProductTextContainer>
            </ProductInfoContainer>
          </ProductContainer>
        </ContentContainer>
        <WhiteBackgroundOverlay />
      </Container>
      <ContentWidestContainer>
        <ContentLargerContainer>
          <RelatedProductTitleContainer>
            <RelatedProductTitle>
              Other <span className={'text-green-500'}> Related Products</span>
            </RelatedProductTitle>
          </RelatedProductTitleContainer>
          <TabContent>
            {randomItemsToRender.map((n) => (
              <ProductListCard
                product={n}
                key={n.id + Math.floor(Math.random() * 100)}
              />
            ))}
          </TabContent>
          {/*<DecoratorBlob1 />*/}
          {/*<DecoratorBlob2 />*/}
        </ContentLargerContainer>
      </ContentWidestContainer>
      <CallToAction
        secondaryLink={false}
        pushDownFooter={false}
        primaryLinkText={'Contact Us'}
        text={`Interested?\nContact us for more details!`}
        primaryLinkUrl={'/contact'}
      />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String) {
    templateSheetsData(pspageslug: { eq: $slug }) {
      pscategory
      psproductname
      psproductdescription
      pspageslug
      psitemcoverimage
      psbrandname
    }
    data: allTemplateSheetsData(skip: 4) {
      nodes {
        pscategory
        psproductname
        psproductdescription
        pspageslug
        psitemcoverimage
        psbrandname
        id
      }
    }
  }
`
// file(relativePath: { eq: $image }) {
//   childImageSharp {
//     fluid(maxWidth: 447, quality: 90) {
//       ...GatsbyImageSharpFluid_withWebp
//     }
//   }
// }

// export const query = graphql`
//   query($slug: String, $random: [String]!, $image: String) {
//     templateSheetsData(pspageslug: { eq: $slug }) {
//       pscategory
//       psproductname
//       psproductdescription
//       pspageslug
//       psitemcoverimage
//       psbrandname
//     }
//     data: allTemplateSheetsData(
//       filter: { pspageslug: { in: $random } }
//       skip: 4
//       limit: 4
//     ) {
//       nodes {
//         pscategory
//         psproductname
//         psproductdescription
//         pspageslug
//         psitemcoverimage
//         psbrandname
//         id
//       }
//     }
//     file(relativePath: { eq: $image }) {
//       childImageSharp {
//         fixed(width: 447) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `
