import { Link } from 'gatsby' //eslint-disable-line
import React from 'react'
import { css } from 'styled-components/macro' //eslint-disable-line
import { motion } from 'framer-motion'
import styled from 'styled-components'
import tw from 'twin.macro'

const styles = {
  shadow: {
    boxShadow: `inset 0 0 0 1000px rgba(0,0,0,.02)`,
  },
}

// const CardContainer = tw.div`flex flex-col mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`
const CardContainer = tw.div`flex flex-col px-1 md:px-4 mb-6 mx-auto md:mx-0 w-11/12 sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/3 self-start      `
const Card = tw(
  motion.div
)`bg-transparent block min-w-3/4 mx-auto sm:max-w-none sm:mx-0 hover:shadow-lg`
// )`flex bg-transparent block w-10/12 md:min-w-3/4 mx-auto sm:max-w-none sm:mx-0 hover:shadow-lg shadow-lg rounded-lg`
// )`flex-1 bg-transparent block max-w-xs mx-auto sm:max-w-none sm:mx-0 rounded-2xl shadow-md hover:shadow-raised`;
const CardImageContainer = styled.div`
  ${(props) =>
    css`
      background-image: url('${props.imageSrc}');
    `}
  ${tw`h-56 xl:h-64 bg-center bg-auto bg-no-repeat relative rounded-t bg-contain`}
`
// const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
// const CardRating = styled.div`
//   ${tw`mr-1 text-sm font-bold flex items-end`}
//   svg {
//     ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
//   }
// `;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`

// const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`flex-1 px-4 py-5 text-gray-700 bg-white rounded-b-lg`
const CardTitle = tw.h5`text-lg font-semibold`
const CardSubtitle = tw.h5`text-sm text-gray-400 font-medium leading-none tracking-wider uppercase`
// const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
// const CardPrice = tw.p`mt-4 text-xl font-bold`;

export const ProductListCard = ({ product }) => {
  return (
    <CardContainer
      key={product.id}
      as={Link}
      to={`/product/${product.pspageslug}`}
    >
      <Card className="group" initial="rest" whileHover="hover" animate="rest">
        <CardImageContainer
          style={styles.shadow}
          //TODO: Change this to <Img>
          imageSrc={`https://ik.imagekit.io/r703ihwwlwd/sinaran/tr:h-370/${product.psitemcoverimage}`}
        >
          {/*<CardRatingContainer>*/}
          {/*  <CardRating>*/}
          {/*    <StarIcon />*/}
          {/*    {card.rating}*/}
          {/*  </CardRating>*/}
          {/*  <CardReview>({card.reviews})</CardReview>*/}
          {/*</CardRatingContainer>*/}
          <CardHoverOverlay
            variants={{
              hover: {
                opacity: 1,
                height: 'auto',
              },
              rest: {
                opacity: 0,
                height: 0,
              },
            }}
            transition={{ duration: 0.3 }}
          />
        </CardImageContainer>
        <CardText>
          <CardSubtitle>{product.psbrandname}</CardSubtitle>
          <CardTitle>{product.psproductname}</CardTitle>
          {/*<CardContent>{product.psproductdescription}</CardContent>*/}
          {/*<CardPrice>{card.price}</CardPrice>*/}
        </CardText>
      </Card>
    </CardContainer>
  )
}
